import { Alert, Box, Snackbar, Typography } from "@mui/material";
import { IReduxState } from "interfaces/redux";
import { ReactElement, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { clearToast } from "redux/toast/action";
import trackPathForAnalytics from "utils/trackPageForAnalytics";

const AnonTemplate = ({
  children,
  title,
}: {
  children: ReactElement | ReactElement[];
  title?: string;
}) => {
  const { toast } = useSelector((state: IReduxState) => state);
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const analytics = useCallback(() => {
    trackPathForAnalytics({
      path: pathname,
      search,
      title: title ?? pathname.split("/")[1],
    });
  }, [pathname, search]);

  useEffect(() => {
    if (title) analytics();
  }, [analytics, title]);

  return (
    <Box
      sx={{
        flexDirection: "column",
        minHeight: "100vh",
        maxHeight: "-webkit-fill-available",
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.png)`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        padding: window.innerHeight < 600 ? "40px 0" : "unset",
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={typeof toast.type === "string"}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(clearToast());
        }}
      >
        <Alert
          onClose={() => {
            dispatch(clearToast());
          }}
          severity={toast.type ?? "warning"}
          sx={{ width: "100%" }}
        >
          <Typography fontWeight={700}>{toast.title}</Typography>
          {toast.description && <Typography fontWeight={400}>{toast.description}</Typography>}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "100vh",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AnonTemplate;
