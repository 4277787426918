import { IReduxUserState } from "interfaces/redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userLogout } from "redux/auth/action";
import { showToast } from "redux/toast/action";
import { getStorage, handleStorage } from "./handleStorage";

export const lastActiveAtKey = `last_active_at`;

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];



export const AutoLogout = (user: IReduxUserState | null) => {
  let timer: any;
  const dispatch = useDispatch();
  const timeoutDuration = 600000;
  const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

  const resetTimer = () => {
    if (timer) {
      handleStorage(lastActiveAtKey, Date.now())
      clearTimeout(timer);
    }
  };

  const doTimeout = () => {
    dispatch(userLogout()); // logs out user & display message
    dispatch(
      showToast({
        type: "error",
        title: "Session Expired",
        description: 'You\'ve been logged out due to inactivity.'
      })
    )
  }

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      doTimeout()
    }, timeoutDuration); // Auto Logout duration.
  };

  useEffect(() => {
    if (!development) {
      if (user) {
        const lastActiveTimestamp = getStorage(lastActiveAtKey)
        if (lastActiveTimestamp && (Date.now() - lastActiveTimestamp) > timeoutDuration) {
          doTimeout();
        }
        else {
          Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
              resetTimer();
              handleLogoutTimer();
            });
          });
        }
      }
    }
  }, [user])
}