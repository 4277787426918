import axios, { AxiosRequestConfig } from "axios";
import { userLogout, userRefreshToken } from "redux/auth/action";
import { store } from "redux/configureStore";
import authProvider, { refreshKey } from "utils/authProvider";

export const baseUrl = process.env.REACT_APP_API_BASE_URL || "";
export const basePath = process.env.REACT_APP_API_PREFIX || "";
export const apiUrl = baseUrl + basePath;

interface Error {
  code: number;
  message: string;
  type: string;
}
export interface ErrorResponse {
  error: Error;
}

const axiosClient = axios.create({
  timeout: 25000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosClient.interceptors.request.use(async (req: AxiosRequestConfig) => {
  if (!req.url?.startsWith("http") && !req.url?.startsWith("//")) {
    if (req.url?.startsWith("/")) {
      req.url = baseUrl + req.url;
    } else {
      req.url = `${apiUrl}/${req.url}`;
    }
  }

  const token = authProvider.getAccessToken();
  const isOurAPI = req.url?.startsWith(baseUrl);
  if (token && isOurAPI) {
    if (!req.headers) {
      req.headers = {};
    }
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

// TODO: handle refresh token flow
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const { dispatch } = store;
    const refereshToken = localStorage.getItem(refreshKey);
    if (!err.response.config.url.includes("login")) {
      if (
        err.response.status === 401 &&
        !err.response.config.url.includes("/token/refresh") &&
        refereshToken
      ) {
        dispatch(
          userRefreshToken({
            token: refereshToken,
          })
        );
      } else if (err.response.status === 401 || (err.response.status === 400 && err.response.config.url.includes("/token/refresh"))) {
        dispatch(userLogout());
      }
    }
    return Promise.reject(err);
  }
);

export default axiosClient;
